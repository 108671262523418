<template>
	<div>
		<div class="row">
			<div class="col"><h2>{{ $t('monte.congelation.notes') }}</h2></div>
			<div class="col-auto">
				<b-button variant="primary" @click.prevent="openAddNote">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']"/></b-button>
			</div>
		</div>
		<div v-if="show_form" class="row">
			<div class="col-12 from-group">
	    		<label>{{ $t("tiers.tiers") }}</label>
				<SearchTiers 
					:tiers_selected.sync="form.tiers"
					:preselected="form.tiers"
				/>
			</div>

			<div class="col-12 form-group">
				<label for="commentaire">{{ $t("monte.congelation.commentaire") }} *</label>
				<e-editor-froala
					identifier="semencenote_commentaire"
					:placeholder="getTrad('monte.congelation.commentaire')"
					:content="commentaire"
					@change="updateCommentaire"
					:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
				></e-editor-froala>
			</div>

			<div class="col-12 text-center from-group">
				<b-button variant="primary"  @click="checkForm">
	                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
	                <font-awesome-icon v-else :icon="['fal', 'save']"></font-awesome-icon> {{ $t("global.enregistrer") }}
	            </b-button>

				<b-button class="ml-1" variant="secondary"  @click="openConfirmDelete">
	                <font-awesome-icon :icon="['far', 'trash']"></font-awesome-icon> {{ $t("global.supprimer") }}
	            </b-button>
			</div>
		</div>
		<LoadingSpinner v-if="loading" />
		<div v-else class="row">
			<div v-for="note in notes" class="col-12 border px-2 mt-2" :key="note.semencenote_id">
				<div>
					<h5 class="d-inline" v-if="note.tiers">{{ note.tiers.tiers_rs }}</h5>
					<a class="float-right" href="#" @click.prevent="openEditNote(note)"><font-awesome-icon :icon="['fal', 'pencil-alt']" /></a>
				</div>
				<div v-html="note.semencenote_commentaire"></div>
			</div>
		</div>

		<ModalConfirm 
			ref="modal_delete_note" 
			:icon="['fal', 'trash-alt']"
			:text_title="$t('monte.congelation.delete_note_title')"
			:text_question="$t('monte.congelation.delete_note_question')"
			:text_message="$t('modal.general.avertissement_suppression')"
			:text_button_ok="$t('action.supprimer')"
			:text_button_cancel="$t('global.annuler')"
			:callback_validation="confirmDeleteNote"
			:callback_cancel="closeModal"
		/>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'StallionNotes',
		mixins: [Congelation],
		props: ['horse_id'],
		data () {
			return {
				loading: false,
				notes: [],
				default_form: {
					semencenote_id: null,
					semencenote_commentaire: '',
					tiers: null
				},
				form: {},
				commentaire: '',
				processing: false,
				show_form: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.notes = await this.getStallionNote(this.horse_id)
				this.loading = false
			},

			updateCommentaire(val) {
				this.form.semencenote_commentaire = val.val
			},

			openAddNote() {
				this.form = _cloneDeep(this.default_form)
				this.show_form = true
			},

			openEditNote(note) {
				this.form = {
					semencenote_id: note.semencenote_id,
					semencenote_commentaire: note.semencenote_commentaire,
					tiers: note.tiers
				}
				this.commentaire = note.semencenote_commentaire
				this.show_form = true
			},

			async checkForm() {
				if(this.form.semencenote_commentaire == '') {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}
				this.processing = true
				const form = {
					semencenote_commentaire: this.form.semencenote_commentaire,
					semencenote_tiers: this.form.tiers ? this.form.tiers.tiers_id : null
				}
				if(this.form.semencenote_id) {
					await this.editStallionNote(this.horse_id, this.form.semencenote_id, form)
				}
				else {
					await this.addStallionNote(this.horse_id, form)
				}
				this.processing = false
				this.show_form = false
				this.init_component()
			},

			openConfirmDelete() {
				this.$refs.modal_delete_note.openModal()
			},

			async confirmDeleteNote() {
				await this.deleteStallionNote(this.horse_id, this.form.semencenote_id)
				this.show_form = false
				this.closeModal()
				this.init_component()
			},

			closeModal() {
				this.$refs.modal_delete_note.closeModal()
			},
		},

		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		}
	}

</script>